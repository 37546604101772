.question {
  position: relative;
  background: transparent;
  font: 16px 'ProximaNova-SemiBold';
  border: 0;
  outline: 0;
  /* padding: 30px 30px 0 30px; */
  margin: 0;
  color: #464D5E;
  cursor: pointer;
  transition: 0.4s;
  width: 100%;
  text-align: left;
}

.question:after {
  content: '\02795';
  position: absolute;
  font-size: 16px;
  right: -16px;
}

.active:after {
    content: "\2796";
}

.answer {
  /* padding: 15px 30px 15px 30px; */
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  font-size: 16px;
  line-height: 1.4;
}

.answer a {
  color: #2CBBB7;
  text-decoration: none;
}
