.gbLogo {
  font-family: 'Comfortaa-Regular' !important;
  letter-spacing: -0.5px;
  color: #1e5e75;
}

.gbLogo span {
  color: #23BFB8;
  font-family: 'Comfortaa-Regular' !important;
}

.gbLogo sup {
  font-size: 12px;
}

.fundraising {
  background: #FFF;
  color: #4A4A4A;
  font-family: 'ProximaNova-Light';
  width: 1200px;
}

b {
  font-family: 'ProximaNova-SemiBold';
}

.fundraising .header__wrapper {
  position: relative;
  padding: 105px 0 40px 0;
  background: linear-gradient(90deg, #1e5e75 0%, #0e9ba6 63%);
}

.fundraising .header__wrapper:before {
  content: '';
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background: url('//cloudfront.startupmedia.com/greenback/icons/gb-icon-white-transparent.png') no-repeat center;
  background-size: 430px auto;
  opacity: 0.09;
}

.fundraising .header__wrapper .header {
  position: relative;
  max-width: 875px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fundraising .header__wrapper .header__img {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
}

.fundraising .header__wrapper .header__img__secondary {
  position: absolute;
  bottom: 0px;
  right: -30px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.fundraising .header__wrapper .header__pitch {
  position: relative;
  max-width: 560px;
  width: 90%;
  margin: 0 0 0 20px;
  color: #fff;
  text-align: center;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
}

.fundraising .header__wrapper .header__pitch h3 {
  max-width: 260px;
  width: 90%;
  font-style: italic;
  margin: 10px 40px 0 auto;
}

.fundraising .donation {
  position: relative;
  max-width: 900px;
  width: 90%;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fundraising .donation__btns {
  position: relative;
  width: 250px;
  flex-shrink: 0;
  text-align: center;
}

.fundraising .donation__btns .btn {
  margin: 20px 0 0 0;
}

.fundraising .donation__details {
  position: relative;
  max-width: 560px;
  width: 90%;
  margin: 0 0 0 40px;
  text-align: center;
}

.fundraising .donation__details h2 {
  color: #1e5e75;
}

.fundraising .donation__bar {
  position: relative;
  margin: 40px auto 20px auto;
  width: 100%;
  height: 25px;
  border-radius: 20px;
  border: 1px solid rgba(30, 100, 122, 0.5);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.fundraising .donation__bar--progress {
  position: relative;
  height: 25px;
  width: 0;
  border-radius: 0;
  transition: all 0.8s ease;
  background: linear-gradient(90deg, #1e5e75 0%, #0e9ba6 63%);
}

.fundraising .donation__amounts {
  font-size: 20px;
  text-align: center;
}

.fundraising .donation__amounts span {
  font: 24px 'ProximaNova-SemiBold';
}

.fundraising .donation__amounts .green {
  color: #23bfb8;
}

.fundraising .donation__amounts .blue {
  color: #1e5e75;
}

.fundraising .donation__amounts .daysLeft {
  position: relative;
  margin: 20px auto 0 auto;
  font: 18px 'ProximaNova-SemiBold';
  width: 175px;
  padding: 8px 0;
  color: #1e5e75;
  background: rgba(44, 187, 183, 0.3);
  border-radius: 5px;
}

.fundraising .details {
  position: relative;
  width: 100%;
  background: #f3f5f6;
  overflow: hidden;
}

.fundraising .details__wrapper {
  position: relative;
  max-width: 1120px;
  width: 90%;
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
}

.fundraising .details__col {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fundraising .details__row {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.fundraising .details__card {
  width: 100%;
  position: relative;
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.fundraising .details__card__title {
  position: relative;
  margin: 0 0 10px 0;
  height: 25px;
  font: 20px 'ProximaNova-SemiBold';
  flex-shrink: 0;
}

.fundraising .details__card__box {
  background: #fff;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
}

.fundraising .details__card__box p {
  font-size: 16px;
  margin: 0 0 30px 0;
  line-height: 1.5;
}

.fundraising .details__card__box p:last-of-type {
  margin: 0;
}

.fundraising .details__card__box img {
  max-width: 300px;
  /* width: 100%; */
  height: auto;
  margin: 0 auto;
}

.fundraising .details__card__box a {
  color: #333;
}

.fundraising .details__card__box--img {
  position: relative;
  height: 80px;
  width: 100%;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.fundraising .details .w33 {
  width: 33.3333%;
}

.fundraising .details .w50 {
  width: 50%;
}

.fundraising .details .w66 {
  width: 66.6666%;
}

.fundraising .details .h100 {
  height: 100%;
}

.details__footer {
  position: relative;
  max-width: 800px;
  width: 90%;
  margin: 30px auto 60px auto;
  font-size: 14px;
  text-align: center;
}

.fundraising .blue__btn {
  position: relative;
  width: 250px;
  line-height: 50px;
  margin: 0 auto;
  text-align: center;
  background: transparent;
  border-radius: 3px;
  color: #FFF;
  background: #49639E;
  font-size: 16px;
  /* cursor: pointer; */
  z-index: 1;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.fundraising .global__btn {
  position: relative;
  width: 250px;
  line-height: 50px;
  margin: 0 auto;
  text-align: center;
  background: #23BFB8;
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
  /* cursor: pointer; */
  z-index: 1;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.fundraising .global__btn a {
  color: #FFF;
  text-decoration: none;
  display: block;
}
